<template>
  <div>
    <el-dialog title="记一笔" width="550px" :visible.sync="visible"
               :before-close="handleClose" :close-on-click-modal="false">
      <el-form ref="form" :model="ruleForm" :rules="rules">
        <el-form-item label="类型">
          <el-radio @change="ruleForm.projectCode = ''" v-model="ruleForm.makeType" label="INCOME">收入</el-radio>
          <el-radio @change="ruleForm.projectCode = ''" v-model="ruleForm.makeType" label="EXPEND">支出</el-radio>
        </el-form-item>
        <el-form-item label="项目" prop="projectCode">
          <el-select v-model="ruleForm.projectCode" class="width-350">
            <el-option v-for="item in filterMakeNoteOpt" :key="item.id"
                       :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收款方式" prop="paymentMethod">
          <el-select v-model="ruleForm.paymentMethod" class="width-350">
            <el-option v-for="(val, key) in dictData['pay-method']" :key="key"
                       :label="val" :value="key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="金额" prop="amount">
          <el-input-number class="width-350" v-model="ruleForm.amount" :precision="2" :step="1" :min="0" :max="999999"></el-input-number>
        </el-form-item>
        <el-form-item label="关联房间">
          <el-button @click="visibleRoom = true" type="primary" size="medium">选择房间</el-button>
          已选择<span class="c-red">{{ ruleForm.roomIdList.length }}</span>间房
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
              class="width-350" v-model="ruleForm.paymentTime" type="datetime"
              placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="凭证">
          <el-upload
              action="#" :auto-upload="false" list-type="picture-card"
              :on-change="handleUpload" :file-list="fileList" :on-remove="removeImage">
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="ruleForm.remark" class="width-350" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSave()">确定</el-button>
    </span>
    </el-dialog>
    <el-dialog title="关联房间" width="550px" :visible.sync="visibleRoom">
      <el-tree
          :props="{ label: 'name', children: 'zones', isLeaf: 'leaf' }"
          :load="loadNode" lazy show-checkbox ref="tree">
      </el-tree>
      <span slot="footer">
         <el-button @click="visibleRoom = false">取消</el-button>
         <el-button type="primary" @click="saveCheckRoom">确定</el-button>
       </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import getDict from '@/utils/dataDict';
import { beforeUpload } from '@/common/js/common';
import { getRoomTypeList, getRoomList, uploadImg, addMakeNote } from '@/api/header/makeNote';
import { getList } from "@/api/pms/accommodationSetting/makeANoteSetting";
export default {
  name: 'MakeNote',
  props: {
    visible: Boolean,
    action: String,
    row: {
      type: Object,
      default: () => {},
    }
  },
  data () {
    return {
      makeNoteOpt: [],
      ruleForm: {
        id: '',
        makeType: 'INCOME',
        projectCode: '',
        paymentMethod: '',
        amount: 0,
        roomIdList: [],
        paymentTime: '',
        certificateUrlList: [],
        remark: ''
      },
      rules: {
        projectCode: [{ required: true, message: '项目不能为空！' }],
        paymentMethod: [{ required: true }],
        amount: [{ required: true }]
      },
      roomTypeList: [],
      visibleRoom: false,
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: []
    }
  },
  computed: {
    ...mapState(['dictData', 'hotelInfo']),
    filterMakeNoteOpt() {
      return this.makeNoteOpt.filter(i => i.itemType === this.ruleForm.makeType)
    }
  },
  mounted() {
    getDict('pay-method')
    this.getIncomeAndExpend()
    this.action === 'edit' && this.getMakeNoteRow()
  },
  methods: {
    // 获取收入支出当前行
    getMakeNoteRow() {
      for (const k in this.ruleForm) {
        if (k in this.row) this.ruleForm[k] = this.row[k]
        else if (k === 'roomIdList') this.ruleForm.roomIdList = JSON.parse(this.row.roomIds || '[]')
        else if (k === 'certificateUrlList') this.ruleForm.certificateUrlList = this.row.certificateUrls
      }
      this.fileList = this.ruleForm.certificateUrlList.map(url => ({ url }))
    },
    // 查询收入支出项
    getIncomeAndExpend() {
      const params = {
        hotelId: this.hotelInfo.id, companyId: this.hotelInfo.storeId
      };
      getList(params).then(({ success, records }) => {
        if (success) this.makeNoteOpt = records
      })
    },
    // 获取房型列表
    loadNode(node, resolve) {
      if (node.level === 0) {
        const params = { roomTypeSource: 'PMS', hotelId: this.hotelInfo.id };
        getRoomTypeList(params).then(({ success, records }) => {
          if (success) resolve(records.map(i => ({ id: i.id, name: i.roomTypeName })))
        })
      } else {
        const params = { roomTypeId: node.data.id };
        getRoomList(params).then(({ success, records }) => {
          if (success) resolve(records.map(i => ({ id: i.id, name: i.roomNo, leaf: true })))
        })
      }
    },
    // 保存选中房间
    saveCheckRoom() {
      this.visibleRoom = false
      this.ruleForm.roomIdList = this.$refs.tree.getCheckedNodes().filter(i => i.leaf).map(i => i.id)
    },
    // 上传图片
    handleUpload(file) {
      const error = beforeUpload(file)
      if (error) return this.$message.error(error)
      let params = new FormData();
      params.append('file', file.raw)
      params.append('hotelId', this.hotelInfo.id)
      params.append('keyPrefix', 'pms/images')
      uploadImg(params).then(({ success, records }) => {
        if (success) this.ruleForm.certificateUrlList.push({ url: records.url, uid: file.uid })
      })
    },
    // 移除图片
    removeImage(file) {
      this.ruleForm.certificateUrlList = this.ruleForm.certificateUrlList.filter(i => {
        return i.uid !== file.uid && i !== file.url
      })
    },
    // 保存一笔
    handleSave() {
      this.$refs.form.validate(valid => {
        if (!valid) return
        this.ruleForm.certificateUrlList = this.ruleForm.certificateUrlList.map(i => i.url || i)
        const params = {
          ...this.ruleForm,
          hotelId: this.hotelInfo.id,
          companyId: this.hotelInfo.storeId
        }
        this.action === 'add' && delete params.id
        addMakeNote(this.action, params).then(({ success }) => {
          if (success) {
            this.$emit('reload')
            this.handleClose()
            this.$message({ message: `${ this.action === 'add' ? '添加' : '编辑' }成功！`, type: 'success' })
          }
        })
      })
    },
    // 关闭会话框
    handleClose() {
      this.$emit('closeMakeNote')
    }
  },
  watch: {
    hotelInfo() {
      this.getIncomeAndExpend()
    }
  }
}
</script>
<style lang="scss" scoped>
.el-form {
  padding: 0 0 0 30px;
  ::v-deep .el-form-item__label {
    width: 80px;
  }
  ::v-deep .el-upload{
    width: 76px; height: 76px; line-height: 84px;
  }
  ::v-deep .el-form-item__error{
    margin: 0 0 0 80px;
  }
  ::v-deep .el-upload-list--picture-card .el-upload-list__item{
    width: 76px; height: 76px;
  }
}
</style>
