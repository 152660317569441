<template>
  <section class="head-top">
    <audio controls="controls" hidden src="../../../assets/cueTone.mp3" ref="audioDom"></audio>
    <el-row class="t-left">
         <template>
            <el-menu
                  :default-active="activeIndex"
                  class="el-menu-demo"
                  mode="horizontal"
                  @select="handleClick"
                  background-color="#087ffd"
                  text-color="#fff"
                  active-text-color="#ffd04b" id="menuScroll-box">
               <el-menu-item v-for="(item, index) in menus" :key="index" :index="index + ''">{{item.name}}</el-menu-item>
            </el-menu>
         </template>
      </el-row>
    <el-row class="t-right">
      <el-row class="lang-box">
        <!-- 酒店列表 -->
        <el-row v-if="userInfo.accountType === 'PLATFORM' || userInfo.accountType === 'TENANT'" class="demo-input-suffix">
          <el-popover
              placement="bottom-start" width="400" v-model="visible" trigger="click">
            <el-row class="pop-top">
              <el-input class="width-240 m-bottom-5 m-right-10" v-model="condition" :placeholder="$t('msg.room_name_code')"  clearable></el-input>
<!--                    <el-button class="m-bottom-5" type="primary" @click="handleSearch(true)" ><span v-text="$t('msg.search')">搜索</span></el-button>
                    <el-button class="m-bottom-5" type="primary" @click="handleSearch(false)"  plain><span v-text="$t('msg.reset')">重置</span></el-button>-->
            </el-row>
            <el-table
                :data="hotelData"
                       highlight-current-row
                @current-change="handleCurrentChange">
              <el-table-column width="60" label="序号">
                <template slot-scope="scope">
                  {{scope.$index + 1}}
                </template>
              </el-table-column>
              <el-table-column width="80" label="LOGO">
                <template slot-scope="scope">
                  <img v-if="scope.row.hotelLogo" :src="scope.row.hotelLogo" width="20" height="20" class="suffix-cursor v-align-middle"/>
                  <img v-else src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" width="20" height="20" class="suffix-cursor v-align-middle" alt="">
                </template>
              </el-table-column>
              <el-table-column property="hotelName" label="酒店名称"></el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination small layout="prev, pager, next" @current-change="changePageNum" :page-size="limit" :total="total"></el-pagination>
            <el-input
                class="width-175"
                placeholder="请选择酒店"
                slot="reference"
                suffix-icon="el-icon-caret-bottom"
                v-model="hotelName">
            </el-input>
          </el-popover>
        </el-row>
        <el-row class="m-right-10" v-else-if="userInfo.accountType === 'HOTEL'">{{ hotelInfo.hotelName }}</el-row>
        <el-row class="m-right-10" v-else>{{ hotelInfo.companyName }}</el-row>
      </el-row>
      <el-row class="user-head" >
        <el-row class="head-pic make-note-img">
          <img @click="visibleMakeNote = true" src="@/assets/make-note-icon.png" alt="">
        </el-row>
        <el-row class="head-pic">
          <!-- 消息提示框 -->
          <el-badge :value="allNew" class="item">
            <i class="el-icon-bell inform" @click="handleInfor()" ></i>
          </el-badge>
          <img :src="logo" alt="" :onerror="errorImg01">
        </el-row>
        <el-row class="head-name">
          <h1>{{userName}}</h1>
        </el-row>
      </el-row>
      <el-row class="person-box" style="margin-left: 15px;">
        <el-dropdown style="font-size: 20px;border: none;padding: 0;background: none;cursor: pointer;">
          <span class="el-dropdown-link">
            <span id="uname"></span>
            <!-- <span id="uname">退出</span> -->
            <i class="el-icon-arrow-down el-icon--right" style="cursor: pointer;"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-text="$t('msg.modify_mobile')" @click.native="dialogMobile = true">修改手机号</el-dropdown-item>
            <el-dropdown-item v-text="$t('msg.modify_pwd')" @click.native="dialogPwd = true">修改密码</el-dropdown-item>
            <el-dropdown-item v-text="$t('msg.exit_a')" @click.native="handleLogout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-row>
    </el-row>
    <!-- 修改手机号 -->
    <el-form label-width="105px" style="line-height: 26px;">
         <el-dialog :title="$t('msg.modify_mobile')" :visible.sync="dialogMobile" width="510px" :before-close="closeMobile">
            <el-row v-if="isShow">
               <el-row class="config-box">
                  <el-row class="m-bottom-15">
                     <el-col :span="7" class="t-a-right">原手机号：</el-col>
                     <el-col :span="17">
                        <el-input  class="width-260" placeholder="被修改的手机号" v-model="oldMobile" oninput="value=value.replace(/[^\d]/g,'')" maxLength='11' clearable></el-input>
                     </el-col>
                  </el-row>
                  <el-row class="m-bottom-15">
                     <el-col :span="7" class="t-a-right" v-text="$t('msg.ver_code_a')">验证码：</el-col>
                     <el-col :span="17">
                        <el-input :placeholder="$t('msg.ver_code')" class="code-inp" v-model="oldCaptcha" oninput="value=value.replace(/[^\d]/g,'')" maxLength='6' clearable></el-input>
                        <el-button type="primary" class="code-btn" @click="getOldCaptcha" :disabled="codeDisabled" v-text="$t('msg.get_code')">获取验证码</el-button>
                     </el-col>
                  </el-row>
               </el-row>
               <el-form-item class="m-left-32">
                  <el-button  @click="resetOld" v-text="$t('msg.reset')">重置</el-button>
                  <el-button  @click="checkCaptcha(oldCaptcha)" type="primary" v-text="$t('msg.next_step')">下一步</el-button>
               </el-form-item>
            </el-row>
            <el-row v-else>
               <el-row class="config-box">
                  <el-row class="m-bottom-15">
                     <el-col :span="7" class="t-a-right">新手机号：</el-col>
                     <el-col :span="17">
                        <el-input class="width-260" placeholder="需要修改的手机号" v-model="newMobile" oninput="value=value.replace(/[^\d]/g,'')" maxLength='11' clearable></el-input>
                     </el-col>
                  </el-row>
                  <el-row class="m-bottom-15">
                     <el-col :span="7" class="t-a-right" v-text="$t('msg.ver_code_a')">验证码：</el-col>
                     <el-col :span="17">
                        <el-input  :placeholder="$t('msg.ver_code')" class="code-inp" v-model="newCaptcha" oninput="value=value.replace(/[^\d]/g,'')" maxLength='6' clearable></el-input>
                        <el-button  type="primary" class="code-btn" @click="getNewCaptcha" :disabled="codeDisabled" v-text="$t('msg.get_code')">获取验证码</el-button>
                     </el-col>
                  </el-row>
               </el-row>
               <el-form-item class="m-left-32">
                  <el-button  @click="resetNew" v-text="$t('msg.reset')">重置</el-button>
                  <el-button  @click="changeMobile" type="primary" v-text="$t('msg.define')">确定</el-button>
               </el-form-item>
            </el-row>
         </el-dialog>
      </el-form>
    <!-- 修改密码 -->
    <el-form label-width="105px" style="line-height: 26px;">
         <el-dialog :title="$t('msg.modify_pwd')" :visible.sync="dialogPwd" width="510px" :before-close="closePwd">
            <el-row class="config-box">
               <el-row class="m-bottom-15">
                  <el-col :span="7" class="t-a-right" v-text="$t('msg.user_name_a')">用户名：</el-col>
                  <el-col :span="17">
                     <el-input  type="text" class="width-260" :placeholder="$t('msg.user_name')" v-model="userName" disabled></el-input>
                  </el-col>
               </el-row>
               <el-row class="m-bottom-15">
                  <el-col :span="7" class="t-a-right" v-text="$t('msg.old_pwd_a')">旧密码：</el-col>
                  <el-col :span="17">
                     <el-input  type="password" class="width-260" :placeholder="$t('msg.inp_old_pwd')" v-model="oldPwd" clearable></el-input>
                  </el-col>
               </el-row>
               <el-row class="m-bottom-15">
                  <el-col :span="7" class="t-a-right" v-text="$t('msg.new_pwd_a')">新密码：</el-col>
                  <el-col :span="17">
                     <el-input  type="password" class="width-260" :placeholder="$t('msg.inp_new_pwd')" v-model="newPwd" clearable></el-input>
                  </el-col>
               </el-row>
               <el-row class="m-bottom-15">
                  <el-col :span="7" class="t-a-right" v-text="$t('msg.confirm_pwd_a')">确认密码：</el-col>
                  <el-col :span="17">
                     <el-input  type="password" class="width-260" :placeholder="$t('msg.inp_ok_pwd')" v-model="confirmPwd" clearable></el-input>
                  </el-col>
               </el-row>
            </el-row>
            <el-form-item class="m-left-32">
               <el-button  @click="resetPwd" v-text="$t('msg.reset')">重置</el-button>
               <el-button  @click="changePwd" type="primary" v-text="$t('msg.immed_modify')">立即修改</el-button>
            </el-form-item>
         </el-dialog>
      </el-form>
    <!--系统通知 -->
    <el-dialog
            title="消息"
            border fit
            :visible.sync="dialogVisible"
            width="50%" class="notice-box"
            :before-close="handleClose" v-if="dialogVisible">
         <el-radio-group v-model="messageType" class="badge-box" @change="tabClick">
            <el-badge :value="allNew" class="item"><el-radio-button type="text" label="">全部消息</el-radio-button></el-badge>
            <el-badge :value="serveNew" class="item"><el-radio-button type="text" label="BOOK" >预定消息</el-radio-button></el-badge>
            <el-badge :value="ShopNew" class="item"><el-radio-button type="text" label="SHOP">商城消息</el-radio-button></el-badge>
            <el-badge :value="preSaleNew" class="item"><el-radio-button type="text" label="COUPON_PRESELL">预售券消息</el-radio-button></el-badge>
         </el-radio-group>
         <el-table
               v-loading="inform_loading"
               :data="informlist"
               highlight-current-rowref="orderTable"
               ref="couponslistdataTable"
               tooltip-effect="light"
               row-class-name="roomtypeRow"
               @selection-change="handleSelectionChange"
               height="450">
            <el-table-column type="selection" width="55" :selectable="checkSelectable"></el-table-column>
            <el-table-column>
               <template slot="header">
                  <el-button type="primary" size="mini" @click="handleRead" :disabled="multipleSelection.length == 0"> 标记为已读 </el-button>
               </template>
               <template slot-scope="scope">
                  <div>{{scope.row.code | filterCode}}</div>
                  <div v-if="scope.row.code === 'PLACE_AN_ORDER' || scope.row.code === 'CONFIRM_ORDER'">
                     {{scope.row.code === 'PLACE_AN_ORDER' ? '预付预订单' : '您有一个新的预付订单'}}
                     [ {{ scope.row.businessName }} ( {{ scope.row.quantity }}间 {{ scope.row.checkInDays }}晚 ) ]：
                     <span style="color: #b3d4fc;cursor: pointer;" @click="routerEvent(scope.row)">{{ scope.row.orderNo }}</span>,
                     {{ scope.row.code === 'PLACE_AN_ORDER' ? '请注意查收' : '已被确认' }}
                  </div>
                  <div v-if="scope.row.code === 'ROOM_SHOP_ORDER_PAY' || scope.row.code === 'FIX_POINT_SHOP_ORDER_PAY' || scope.row.code === 'EXPRESS_SHOP_ORDER_PAY'">
                     您有一个新的商城订单需要处理：<span style="color: #b3d4fc;cursor: pointer;" @click="routerEvent(scope.row)">{{ scope.row.orderNo }}</span>,请注意查收
                  </div>
                  <div v-if="scope.row.code === 'COUPON_PRESELL_ORDER_PAY'">
                     您有一个新的预售券订单[ {{ scope.row.businessName }} ]：<span style="color: #b3d4fc;cursor: pointer;" @click="routerEvent(scope.row)">{{ scope.row.orderNo }}</span>,请注意查收
                  </div>
               </template>
            </el-table-column>
            <el-table-column width="160">
               <template slot-scope="scope">
                  <div>{{ scope.row.createTime}}</div>
                  <span :style="{float:'right',color: scope.row.isRead ? '#88c12f' : '#d9001b'}" >{{ scope.row.isRead ? '已读' : '未读' }}</span>
               </template>
            </el-table-column>
         </el-table>
         <el-row>
            <el-col :span="8" style="margin-top: 35px;">
               <el-checkbox v-model="cueTone" @change="handleCueTone">播放通知提示音</el-checkbox>
               <el-checkbox v-model="newPopup" @change="handleNewPopup">新消息自动弹窗</el-checkbox>
            </el-col>
            <el-col :span="16">
               <!-- 分页 -->
               <pagination :total="inform_total" :page-size="inform_limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging" v-if="bool" />
            </el-col>
         </el-row>
      </el-dialog>
    <!-- 记一笔 -->
    <make-note v-if="visibleMakeNote" :visible="visibleMakeNote" @closeMakeNote="visibleMakeNote = false" action="add"/>
   </section>
</template>
<script>
import { urlObj } from '@/api/interface'
import { system } from '@/api/interface/system'
import { mapState } from "vuex";
import { dateFactory } from '@/common/js/common';
import makeNote from './MakeNote'
export default {
   data () {
      return {
         hotelData: [],        // 酒店列表
         condition: '',        // 酒店名称
         total: 0,             // 酒店列表总数
         limit: 7,             // 每页数
         page: 1,              // 当前页
         visible: false,
         logo:'',
         errorImg01:'this.src="' + require('@/assets/下载.png') + '"',
         activeIndex: '0',
         hotelName: '',        // 酒店名称
         menuIndex: '0',       // 主菜单当前索引值
         activeName: 0,        // 主菜单选中状态
         oldMobile: '',        // 原手机号
         oldCaptcha: '',       // 原手机号验证码
         type: 'MOBILE_CHANGE',// 请求验证码的需求类型
         isShow: true,         // 显示当前验证内容
         newMobile: '',        // 新手机号
         newCaptcha: '',       // 新手机号验证码
         userName: '',         // 用户名
         dialogMobile: false,  // 修改手机号弹窗
         dialogPwd: false,     // 修改密码弹窗
         oldPwd: '',           // 原用户密码
         newPwd: '',           // 新用户密码
         confirmPwd: '',       // 确认新密码
         userInfo: {},
        visibleMakeNote: false,

         /* 通知消息 */
         userId:"",            // 用户id
         messageType:'',       // 通知消息类型
         allNew:null,           //全部消息
         serveNew:null,         //预定消息
         ShopNew:null,          //商城消息
         preSaleNew:null,       //预售券消息

         dialogVisible:false,    //弹窗显示
         informlist:[],          //通知弹窗列表数据
         inform_loading:true,
         multipleSelection:[],   //选中值
         cueTone:false,          // 播放通知提示音
         newPopup:true,         // 新消息自动弹窗
         inform_total: 0,        // 用户列表总条目数
         inform_page: 1,         // 当前页 默认第一页
         inform_limit: 0,        // 每页显示数
         bool:true,              // 分页刷新


         // websocket
         data:0,
         IntervaObj: null,//心跳心跳倒计时
         websocket: null,

         socket:null,
         socketCount:0,          // 重连次数5时停止重连
         socketCountQ:0,         // 连接关闭次数5时停止重连
         lockReconnect:false,    // 避免重现连接

         // 提示文本
         request_success: '验证码请求成功，请注意接收！',
         inp_code: '请输入验证码！',
         confirm: '确定',
         update_success: '修改成功！',
         captcha_error: '验证码错误！',
         selectValue: '',
         langCn: true,
         isCollapse: false,
         codeDisabled: false, // 按钮状态
         get_code: '获取验证码',
         inp_mobile: '请输入手机号！',
         mobile_no_null: '手机号不能为空！',
         mobile_error: '手机号格式错误！',
         reacquire: ' 秒后重新获取',
         inp_old_pwd: '请输入旧密码！',
         inp_pwd: '请输入密码！',
         pwd_len_6_16: '密码长度在 6 到 16 个字符！',
         pwd_inconsistent: '两次密码不一致！',

      }
   },
   props: {
      menus: Array,
      index: String,
      isName: Boolean    // 是否显示后台名称
   },
  components: {
    makeNote
  },
   inject: ['reload'],
   computed: { ...mapState(['hotelInfo']) },
   created() {
      let menber = JSON.parse(sessionStorage.getItem('userInfo'))
      this.logo = window.getResourcesHost().replace('http', 'https') + menber.headPortrait
   },
   mounted () {
      this.inform_limit = sessionStorage.getItem('pageSize') * 1
      if (sessionStorage.getItem('hotelInfo')) {
         let hotelInfo = JSON.parse(sessionStorage.getItem('hotelInfo'))
         this.$store.dispatch('setHotelInfo', hotelInfo)
         this.hotelName = hotelInfo.hotelName
      }
      this.selectValue = !localStorage.lang ? 'zh_CN' : localStorage.lang
      this.activeIndex = sessionStorage.getItem('menuIndex')
      this.userInfo = JSON.parse(sessionStorage.getItem('userInfo') || '{}')
      this.userName = JSON.parse(sessionStorage.getItem('userInfo')).userName
      this.userId = JSON.parse(sessionStorage.getItem('userInfo')).userId
      this.getNewEvent()
      this.getIndex()
      this.getHotels()
      this.scrollInit()
      //如果有本地缓存进行赋值，没有的话默认为开启提示
      if(localStorage.getItem('cueTone')) {
         this.cueTone = localStorage.getItem('cueTone') === 'true'
      }else{
         this.cueTone = false
      }
      if(localStorage.getItem('newPopup')) {
         this.newPopup = localStorage.getItem('newPopup') === 'true'
      }else{
         this.newPopup = true
      }
      this.initWebSocket()
   },
   destroyed() {
      clearInterval(this.IntervaObj)
      this.IntervaObj = null
      this.websocket.close() // 关闭websocket
   },
   methods: {
      // 获取通知弹窗框数据
       handleInfor(){
          this.dialogVisible = true
         const url = system.eventNotifyData
         let param = {
            page:this.inform_page,
            limit:this.inform_limit,
            userId:this.userId,
            messageType: this.messageType, //通知消息类型
            type:'PLATFORM',
            hotelId:this.hotelInfo.id
         }
         this.$axios.post(url,param).then(res => {
            if (res.success) {
               this.inform_loading = false
               this.inform_total = res.total
               this.informlist = res.records
            }
         })
      },
      // 获取未读消息
      getNewEvent(){
         this.serveNew = 0
         this.ShopNew = 0
         this.preSaleNew = 0
         const url = system.getUnreadNotify
         let param = {
            hotelId:this.hotelInfo.id,
            userId:this.userId,
         }
         this.$axios.get(url,param).then(res =>{
            if(res.success){
               let arr = res.records
               /*
               * CONFIRM_ORDER  PLACE_AN_ORDER   为预定消息
               * EXPRESS_SHOP_ORDER_PAY  FIX_POINT_SHOP_ORDER_PAY ROOM_SHOP_ORDER_PAY  商城
               * COUPON_PRESELL_ORDER_PAY 预售券
               * */
               if (!arr.hasOwnProperty('CONFIRM_ORDER')) arr.CONFIRM_ORDER = 0
               if (!arr.hasOwnProperty('PLACE_AN_ORDER')) arr.PLACE_AN_ORDER = 0
               this.serveNew = arr.CONFIRM_ORDER + arr.PLACE_AN_ORDER
               if (!arr.hasOwnProperty('EXPRESS_SHOP_ORDER_PAY')) arr.EXPRESS_SHOP_ORDER_PAY = 0
               if (!arr.hasOwnProperty('FIX_POINT_SHOP_ORDER_PAY')) arr.FIX_POINT_SHOP_ORDER_PAY = 0
               if (!arr.hasOwnProperty('ROOM_SHOP_ORDER_PAY')) arr.ROOM_SHOP_ORDER_PAY = 0
               this.ShopNew = arr.EXPRESS_SHOP_ORDER_PAY + arr.FIX_POINT_SHOP_ORDER_PAY + arr.ROOM_SHOP_ORDER_PAY
               if (arr.hasOwnProperty('COUPON_PRESELL_ORDER_PAY')) this.preSaleNew  = arr.COUPON_PRESELL_ORDER_PAY
               this.allNew = this.ShopNew + this.serveNew + this.preSaleNew
               if(this.allNew === 0 ) this.allNew = null
               if(this.serveNew === 0) this.serveNew = null
               if(this.ShopNew === 0) this.ShopNew = null
               if(this.preSaleNew === 0) this.preSaleNew = null
            }
         })
      },
      // 通知消息tab切换
      tabClick() {
         this.inform_page = 1
         this.bool = false
         this.inform_limit = 10
         this.$nextTick(()=>{
            this.bool = true
         })
         this.handleInfor()
      },
      // 跳转路由
      routerEvent(row){
         let menuIndex = ''
         let sideIndex = ''
         if(row.code === 'PLACE_AN_ORDER' || row.code === 'CONFIRM_ORDER' ) {
            let ons = JSON.parse(sessionStorage.getItem('hotelInfo'))
            if(ons.isEbooking === 'YES'){
               menuIndex = '7'
               sideIndex = '4'
               this.$router.push('/hotelRes_order/eBooking_reserve')
            }else{
               if(ons.hotelCode === 'dayizhu'){
                  menuIndex = '6'
                  sideIndex = '1-0'
                  this.$router.push('/reserve_list')
               }else{
                  menuIndex = '3'
                  sideIndex = '6-3'
                  this.$router.push('/hotelRes_order/hotel_order')
               }
            }
         }else if(row.code === 'COUPON_PRESELL_ORDER_PAY'){ //预售券
            menuIndex = '3'
            sideIndex = '1-4-0'
            this.$router.push('/preSaleDetails')
         }else{ //商城
            menuIndex = '3'
            sideIndex = '6-0'
            this.$router.push('/order_manage') // 订单列表
         }
         sessionStorage.setItem('menuIndex', menuIndex )
         sessionStorage.setItem('sideIndex', sideIndex )

         // 未读时实现跳转的同时当前信息设置为已读
         if (!row.isRead){
            this.multipleSelection = [{
               businessId:row.businessId,
               messageType:row.messageType,
               code:row.code
            }]
            this.handleRead('read')
         }else{
            this.reload()
         }
         this.inform_limit = 10
         this.dialogVisible = false
      },
      // 关闭弹窗
      handleClose(){
         this.inform_page = 1
         this.inform_limit = 10
         this.dialogVisible = false
         this.messageType = '' //重置消息tab选项
      },

      // 标记为已读
      handleRead(read){
          // console.log(read)
         const url = system.readNotify
         let param = {
            hotelId:this.hotelInfo.id,
            userId:this.userId,
            eventNotifyDataList:this.multipleSelection
         }
         this.$axios.post(url,param,'json').then(res => {
            if (res.success) {
               if(read !== 'read'){
                  this.handleInfor()  //表格数据
                  this.$message('已成功标记为已读');
                  this.getNewEvent() // 消息总数
               }else{
                  this.reload()
               }
            }
         })
      },
      //选择项发生变化时触发
      handleSelectionChange(val){
         let arr = []
         val.forEach(item=>{
            arr.push(
               Object.assign({
                  businessId:item.businessId,
                  messageType:item.messageType,
                  code:item.code
               })
            )
         })
         this.multipleSelection = arr;
      },
      // 设置表格可选中项   已读不可选 未读时可选
      checkSelectable(row) {
         return !row.isRead
      },
      //表格行 class名
      roomtypeRow(){ return "roomtypeRow" },
      //播放通知提示音
      handleCueTone(){
         localStorage.setItem('cueTone',this.cueTone)
         this.$refs.audioDom.currentTime = 0;
         if(this.cueTone) this.$refs.audioDom.play(); //播放
      },
      //新消息自动弹窗
      handleNewPopup() { localStorage.setItem('newPopup',this.newPopup) },

      initWebSocket () {
         // 判断当前环境是否支持 websocket
         if (typeof (WebSocket) == 'undefined') {
            this.$notify({
               title: '提示',
               message: '当前浏览器无法接收实时报警信息，请使用谷歌浏览器！',
               type: 'warning',
               duration: 0
            });
         } else {
            this.websocket = new WebSocket(window.getSocketHost() + "/notify")
            // 连接成功建立的回调方法
            this.websocket.onopen = this.setOnopenMessage
            //  接受到消息的回调方法
            this.websocket.onmessage = this.setOnmessageMessage
            // 接受到服务端关闭连接时的回调方法
            this.websocket.onclose = this.setOncloseMessage
            // 连接发生错误的回调方法
            this.websocket.onerror = this.setErrorMessage
            // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
            window.onbeforeunload = this.closeWebSocket
         }
      },
      // 连接成功建立的回调方法
      setOnopenMessage () {
         this.websocket.send("user:" + this.userId)
         this.start(); // 开启心跳
      },
      // 接受到消息的回调方法
      setOnmessageMessage (event) {
         // 转换为json对象
         let data = JSON.parse(event.data);
         // 过滤掉非当前酒店通知消息
         console.log(data,'WebSocket data')
         if(data.hotelId === this.hotelInfo.id){
            // console.log(data,'WebSocket data')
            this.getNewEvent() // 获取消息数
            //有消息自动弹窗
            if(this.newPopup) this.handleInfor()
            // 提示音
            if(this.cueTone){
                  this.$refs.audioDom.currentTime = 0; //从头开始播放提示音
                  this.$refs.audioDom.play(); //播放
             //   this.$nextTick(()=>{
             //    this.$refs.audioDom.currentTime = 0; //从头开始播放提示音
             //    this.$refs.audioDom.play(); //播放
             //    setTimeout(() => {
             //       this.$refs.audioDom.pause()
             //       this.$refs.audioDom.load()
             //    }, 5000)
             // })
            }
         }
         // 收到服务器信息，心跳重置
         this.reset();
      },
      // 接受到服务端关闭连接时的回调方法
      setOncloseMessage (res) {
         this.socketCountQ++;
         if(this.socketCountQ <= 5){
            console.log( "WebSocket连接关闭" + '   状态码：' + this.websocket.readyState,dateFactory.getDistanceToday(0),res.code)
            // 重连
            this.reconnect();
           // this.websocket.close()
           // this.initWebSocket()
         }else{
            this.websocket.close() // 关闭websocket
         }
      },
      // 连接发生错误的回调方法
      setErrorMessage () {
         //重连5次还是连接不上则关闭websocket
         this.socketCount++;
         if(this.socketCount <= 5){
            this.reconnect();
            console.log("WebSocket连接发生错误" + ' 状态码：' + this.websocket.readyState)
         }else{
            this.websocket.close()
         }
      },
      // 当窗口关闭时，主动去关闭websocket连接
      closeWebSocket() {
         this.websocket.close() // 关闭websocket
      },
      // 重新连接
      reconnect () {
          // 避免重新连接
          if(this.lockReconnect) return
         this.lockReconnect = true
         // 设置延时器避免请求过多
         setTimeout(()=>{
            console.log('重新连接',this.websocket)
            this.initWebSocket();
            this.lockReconnect = false;
         },10* 1000)
      },
      // 重置心跳
      reset () {
         // 清除时间
         clearInterval(this.IntervaObj)
         this.IntervaObj = null
         // 重启心跳
         this.start();
      },
      // 开启心跳 30s发送一次
      start () {
         if(this.IntervaObj){
            clearInterval(this.IntervaObj)
            this.IntervaObj = null
         }
         this.IntervaObj = setInterval(() => {
            // 如果连接正常，每30s发送心跳
            if (this.websocket && this.websocket.readyState == 1) {
               this.websocket.send(' ')
            } else { // 否则重连
               this.reconnect();
            }
         }, 30 * 1000)
      },
      // 获取当前菜单索引值
      getIndex(){
         this.menuIndex = sessionStorage.getItem('menuIndex')
         this.activeName = this.menuIndex
      },
      // 点击主导航菜单
      handleClick(index) {
         this.menuIndex = index
         this.$emit('clickMenu', this.menuIndex)
         const path = this.menus[this.menuIndex].path
         this.$router.push(path)
      },
      // 获取原手机验证码
      getOldCaptcha(){
         // 表单验证
         if (!this.oldMobile) {
            this.$alert(this.inp_mobile, '', {
               confirmButtonText: this.confirm,
               type: 'error'
            })
            return
         }
         const regMobile = /^[1][34578]\d{9}$/
         if (!regMobile.test(this.oldMobile)) {
            this.$alert(this.mobile_error, '', {
               confirmButtonText: this.confirm,
               type: 'error'
            })
            return
         }

         let url = urlObj.reqVerCode
         let param = {
            mobile: this.oldMobile,
            type: this.type
         }
         this.$axios.get(url, param).then(res => {
            if (res.success){
               this.$message({
                  showClose: true,
                  message: this.request_success,
                  type: 'success'
               })
            }
         }).catch(error => {
            console.log(error)
         })
      },
      // 检验验证码
      checkCaptcha(captcha){
         return new Promise((resolve, reject) => {
            if (!this.oldCaptcha || this.oldCaptcha.length < 6) {
               this.$alert(this.inp_code, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            const param = {
               captcha: captcha
            }
            const url = urlObj.codeVerify
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.isShow = false
                  resolve(true)
                  return
               }
               reject(false)
            }).catch(error => {
               console.log(error)
            })
         })
      },
      // 重置原手机号表单
      resetOld(){
         this.oldMobile = ''
         this.oldCaptcha = ''
      },
      // 获取新手机号验证码
      getNewCaptcha(){
         // 表单验证
         if (!this.newMobile) {
            this.$alert(this.inp_mobile, '', {
               confirmButtonText: this.confirm,
               type: 'error'
            })
            return
         }
         const regMobile = /^[1][34578]\d{9}$/
         if (!regMobile.test(this.newMobile)) {
            this.$alert(this.mobile_error, '', {
               confirmButtonText: this.confirm,
               type: 'error'
            })
            return
         }
         let url = urlObj.reqVerCode
         let param = {
            mobile: this.newMobile,
            type: this.type
         }
         this.$axios.get(url, param).then(res => {
            if (res.success){
               this.$message({
                  showClose: true,
                  message: this.request_success,
                  type: 'success'
               })
            }
         })
      },
      // 重置新手机号表单
      resetNew(){
         this.newMobile = ''
         this.newCaptcha = ''
      },
      // 保存修改手机号
      async changeMobile(){
         // 验证验证码
         const result = await this.checkCaptcha(this.newCaptcha)
         if (!result){
            this.$message({
               showClose: true,
               message: this.captcha_error,
               type: 'error'
            })
            return
         }
         // 表单验证
         if (!this.newMobile) {
            this.$alert(this.inp_mobile, '', {
               confirmButtonText: this.confirm,
               type: 'error'
            })
            return
         }
         const regMobile = /^[1][34578]\d{9}$/
         if (!regMobile.test(this.newMobile)) {
            this.$alert(this.mobile_error, '', {
               confirmButtonText: this.confirm,
               type: 'error'
            })
            return
         }
         if (!this.oldCaptcha || this.oldCaptcha.length < 6) {
            this.$alert(this.inp_code, '', {
               confirmButtonText: this.confirm,
               type: 'error'
            })
            return
         }
         // 发送请求
         const param = {
            mobile: this.oldMobile,
            newMobile: this.newMobile,
            captcha: this.newCaptcha,
            captchaType: this.type
         }
         const url = system.changeMobile
         this.$axios.post(url, param).then(res => {
            if (res.success){
               this.$message({
                  showClose: true,
                  message: this.update_success,
                  type: 'success'
               })
               this.closeMobile()
            }
         }).catch(error => {
            this.$message.error(error)
         })
      },
      // 关闭修改手机号弹窗
      closeMobile(){
         this.dialogMobile = false
         this.resetOld()
         this.resetNew()
      },
      // 重置用户密码
      resetPwd(){
         this.oldPwd = ''
         this.newPwd = ''
         this.confirmPwd = ''
      },
      // 修改用户密码
      changePwd(){
         // 表单验证
         if (!this.oldPwd) {
            this.$alert(this.inp_old_pwd, '', {
               confirmButtonText: this.confirm,
               type: 'error'
            })
            return
         }
         if (!this.newPwd || !this.confirmPwd) {
            this.$alert(this.inp_pwd, '', {
               confirmButtonText: this.confirm,
               type: 'error'
            })
            return
         }
         if (this.newPwd.length < 6 || this.newPwd.length > 16 || this.confirmPwd.length < 6 || this.confirmPwd.length > 16) {
            this.$alert(this.pwd_len_6_16, '', {
               confirmButtonText: this.confirm,
               type: 'error'
            })
            return
         }
         if (this.newPwd !== this.confirmPwd) {
            this.$alert(this.pwd_inconsistent, '', {
               confirmButtonText: this.confirm,
               type: 'error'
            })
            return
         }
         const url = urlObj.modifyPwd
         const param = {
            password: this.oldPwd,
            newPassword: this.newPwd,
         }
         this.$axios.post(url, param).then(res => {
            if (res.success){
               this.$message({
                  showClose: true,
                  message: this.update_success,
                  type: 'success'
               })
               this.resetPwd()
            }
         }).catch(error => {
            this.$message.error(error)
         })
      },
      // 关闭修改密码弹窗
      closePwd(){
         this.dialogPwd = false
         this.resetPwd()
      },
      // 退出登录
      handleLogout(){
         const url = urlObj.logout
         this.$axios.post(url, {}).then(res => {
            if (res.success) {
               this.$router.push('/login');
               sessionStorage.setItem('router', '/login')
            }
         }).catch(error => {
            let response = error.response;
            if(response && response.data && response.data.message){
               let message = response.data.message;
               message = message.split(':');
               if(message.length > 1){
                  // 您没有携带有效Token，无法访问后台服务！
                  console.log(message[1]);
                  return;
               }
            }
            this.$router.push('/login');
            sessionStorage.setItem('router', '/login')
         })
      },
      // 获取酒店列表
      getHotels: function () {
         const url = urlObj.getHotelPage + `?limit=${this.limit}&page=${this.page}`
         const param = { condition: this.condition, hotelId: this.userInfo.extra && this.userInfo.extra.hotelId || '', companyId: this.userInfo.storeId || '' }
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               this.total = res.total
               let hotelData = res.records
               if (hotelData.length === 0){
                 this.hotelData=[]
                 return
               }
               hotelData.forEach(item => {
                  item.hotelLogo = item.hotelLogo && window.getResourcesHost() + item.hotelLogo
               })
               this.hotelName = this.hotelName || hotelData[0].hotelName
               this.hotelData = hotelData
            }
         }).catch(error => {
            this.$message.error(error)
         })
      },
      // 搜索酒店
      handleSearch(bool){
         if (bool) return this.getHotels()
         this.condition = ''
      },
      // 改变当前页码
      changePageNum(num){
         this.page = num
         this.getHotels()
      },
      // 改变每页数
      pageChange(num) {
         this. inform_limit = num
         this.handleInfor()
      },
      // 改变当前页
      handlePaging(num) {
         this. inform_page = num
         this.handleInfor()
      },
      // 选中当前酒店
      handleCurrentChange(val){
         this.visible = false
         this.hotelName = val.hotelName
         this.$store.dispatch('setHotelInfo', val)
         sessionStorage.setItem('hotelInfo', JSON.stringify(val))
      },
      //顶部滚动事件
      scrollInit() {
         // 获取要绑定事件的元素
         const scrollDiv = document.getElementById("menuScroll-box");
         scrollDiv.addEventListener('mousewheel', handler, false)//大部分浏览器兼容性
         document.addEventListener('DOMMouseScroll', handler, false)//火狐兼容性
         // 滚动事件函数
         function handler(event) {
            // 获取滚动方向
            let detail = event.detail || event.wheelDelta;
            // 定义滚动距离
            let step = 0;
            // 判断滚动方向
            if (detail < 0) {
               // 数量1为定义滚动方向为左
               step = 1 * 50;  //*50 自定义滚动幅度
            } else {
               // 数量1为定义滚动方向为右
               step = -1 * 50;
            }
            // 对需要滚动的元素进行滚动操作
            scrollDiv.scrollLeft += step;
         }
      }
   },
   watch: {
     condition(){
       this.page = 1
       this.handleSearch(true)
     },
      menuIndex: {
         handler(newVal){
            sessionStorage.setItem('menuIndex', newVal)
         }
      },
       hotelInfo(newVal,oldVal) {
         oldVal && newVal.id !== oldVal.id && this.getNewEvent()
           this.handleCurrentChange(newVal)
       },deep:true,

   },
   filters:{
      filterCode(val){
         switch (val){
            case "PLACE_AN_ORDER":
               return '订房支付成功提醒';
            case "CONFIRM_ORDER":
               return '订房已确认提醒';
            case "ROOM_SHOP_ORDER_PAY":
               return '商城支付成功提醒';
            case "FIX_POINT_SHOP_ORDER_PAY":
               return '商城支付成功提醒';
            case "EXPRESS_SHOP_ORDER_PAY":
               return '商城支付成功提醒';
            case "COUPON_PRESELL_ORDER_PAY":
               return '预售券购买成功提醒';
         }
      }
   }

}
</script>

<style lang="scss" scoped>
.head-top{
   height: 80px; overflow: hidden;
   box-sizing: border-box; display: flex; justify-content: space-between;
   .t-left {
      flex: 1; overflow: hidden; padding: 0px 0px 0 15px;
      .el-menu-demo{ width: 100%; white-space: nowrap; overflow-x: hidden }
      .el-menu-item{
         font-size: 18px; font-weight: 500; height: 80px; line-height: 80px; padding: 0 32px }
      @media screen and (max-width: 1200px) {
         .el-menu-item{ padding: 0 10px; font-size: 13px }
      }
      @media screen and (max-width: 900px) {
         .el-menu-item{ padding: 0 4px; font-size: 12px!important; }
      }
      // 配合顶部滚动事件
      #menuScroll-box{
         li{
            float: none;
            display: inline-block!important;
         }
      }
   }
   .t-right {
      font-size: 13px; color: #ffffff;
      div{ display: inline-block; line-height: 0 }
      .person-box{
         margin-left: 30px;
         .el-dropdown-link{ color: #ffffff }
         .el-dropdown{
            padding: 5px 8px; border-radius: 3px; border: solid 1px #4db0fe;
            background-color: #3d9efe; font-size: 12px;
         }
         .el-dropdown-menu{
            text-align: center;
         }
      }
      .user-head{
         position: relative; top: 15px;
         .head-pic img{
            width: 45px; height: 45px; border-radius: 100%;
         }
        .make-note-img img {
          border-radius: 0; height: 35px; width: 35px;
          margin: 0 8px;
        }
         .inform{
            position: relative; top: -20px; font-size: 40px;
         }
         .head-name h1{
            position: relative; top: -15px; left: 10px;
         }
      }
      .lang-box { box-sizing: border-box }
      .config-box{
         .code-inp,.code-btn{
            width: 110px;
            float: left;
         }
         .code-btn{
            width: 140px; margin-left: 10px;
         }
      }
      .demo-input-suffix{
         margin-right: 10px;
         .pop-top{
            border-bottom: 1px #EBEEF5 solid;
         }
      }
   }
   .notice-box{
      line-height: 0px;
      .badge-box{
         ::v-deep .el-badge__content{ top: 0!important }
      }
   }
   ::v-deep .roomtypeRow td{ padding: 8px 0 }
   .item {
      margin-top: 10px;
      margin-right: 20px;
      ::v-deep sup{
         top: -10px!important;
      }
   }
}
</style>

