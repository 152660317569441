import request from "@/api/service";

const api = {
   roomTypeList: '/pms/roomtype/getList',
   roomList: '/pms/room/getList',
   upload: '/system/aliyun/oss/uploadImage',
   add: '/pms/makeNote/add',
   edit: '/pms/makeNote/edit'
}

export function getRoomTypeList(params) {
   return request({
      url: api.roomTypeList,
      method: 'POST',
      data: params
   })
}

export function getRoomList(params) {
   return request({
      url: api.roomList,
      method: 'POST',
      data: params
   })
}

export function uploadImg(params) {
   return request({
      url: api.upload,
      method: 'POST',
      data: params,
      
   })
}

export function addMakeNote(action, params) {
   return request({
      url: api[action],
      method: 'POST',
      data: params
   })
}
