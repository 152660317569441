import request from "@/api/service";

const api = {
   list: '/pms/makeNoteItem/getList',
   add: '/pms/makeNoteItem/add',
   delete: '/pms/makeNoteItem/delete',
   sort: '/pms/makeNoteItem/sort'
}

export function getList(params) {
   return request({
      url: api.list,
      method: 'GET',
      params
   })
}

export function addItem(params) {
   return request({
      url: api.add,
      method: 'POST',
      data: params
   })
}

export function delItem(query) {
   return request({
      url: api.delete + query,
      method: 'POST',
   })
}

export function sortItem(params) {
   return request({
      url: api.sort,
      method: 'POST',
      data: params
   })
}
