<template>
   <!-- 大屏悬浮窗 -->
   <el-row class="suspension">
      <img v-show="!showSuspensionBtn" @mouseleave="leave" @click="showDrawer" width="80" src="@/assets/suspension-button.gif" alt="">
      <img v-show="showSuspensionBtn" @mouseenter="showSuspensionBtn = false" width="13" src="@/assets/suspension-button-zoom.png" alt="">
      <el-drawer
         title="我是标题"
         direction="ttb"
         size="100%"
         :modal="false"
         @opened="() => open()"
         :visible.sync="visibleDrawer"
         :with-header="false">
         <el-row class="screen-main">
            <el-row class="top-title" type="flex">
               <el-col :span="4" class="left"><span @click="showDrawer">最小化</span></el-col>
               <el-col :span="16" class="center">酒店数字化平台数据中心</el-col>
               <el-col :span="4" class="right"><span :class="shopListShow ? 'active' : ''" @click="shopListShow = !shopListShow">巡店系统</span><span @click="logOut">退出系统</span></el-col>
            </el-row>
            <el-row class="btm-cont">
               <el-col :span="6" class="left-side side">
                  <el-row class="box">
                     <el-row class="data" >
                        <el-row class="sub-title">合作数据</el-row>
                        <el-row id="cooperation" style='height: 100%'></el-row>
                     </el-row>
                  </el-row>
                  <el-row class="box">
                     <el-row class="data" id="sellTable">
                        <el-row class="sub-title">销售数据</el-row>
                       <el-row  class="market-data">
                         <el-tabs v-model="activeName" @tab-click="handleClick">
                           <el-tab-pane v-for="item in tabList" :key="item.name" :label="item.name" :name="item.value" />
                         </el-tabs>
                         <el-row :gutter="20"  class='tab-title' style="margin: 0">
                           <el-col :span="6" style="padding-left: 0"><div class="grid-content bg-purple" v-if="activeName=='hotelList'">酒店数据</div></el-col>
                           <el-col :span="6" style="padding-left: 0"><div class="grid-content bg-purple" v-if="activeName=='groupList'">集团数据</div></el-col>
                           <el-col :span="6" style="padding-left: 0"><div class="grid-content bg-purple" v-if="activeName=='leseeList'">租户数据</div></el-col>

                           <el-col :span="6" ><div class="grid-content bg-purple content" v-if="activeName=='hotelList'">会员数量</div></el-col>
                            <el-col :span="6" ><div class="grid-content bg-purple content" v-if="activeName=='groupList'">用户数量</div></el-col>
                           <el-col :span="6" ><div class="grid-content bg-purple content" v-if="activeName=='leseeList'">集团数量</div></el-col>
<!--                           <p class="unitsize">(套)</p>-->
                            <el-col :span="6"><div class="grid-content bg-purple content" v-if="activeName=='groupList'">门店数量</div></el-col>
                           <el-col :span="6"><div class="grid-content bg-purple " style="text-align: right" v-if="activeName=='groupList'">月销售额</div></el-col>
                           <el-col :span="6"><div class="grid-content bg-purple content"  v-if="activeName !='groupList'">月销售额</div></el-col>
                           <el-col :span="6"><div class="grid-content bg-purple" style="text-align: right" v-if="activeName=='hotelList'">日销售额</div></el-col>
                           <el-col :span="6"><div class="grid-content bg-purple content" style="text-align: right" v-if="activeName=='leseeList'">酒店数量</div></el-col>
<!--                           <p class="unitsize">(万元)</p>-->
<!--                           <p class="unit content">(元)</p>-->
                         </el-row>
                         <div  class="tab-box" :style="{height: sellTableHeight}">

                           <div   class='tab-data' style="margin: 0" v-for="item in sellDataList" :key="item.id">

                             <div style="display: flex">
                               <el-tooltip class="item" effect="dark"  :content="activeName=='hotelList'?item.hotelName:item.companyName" placement="top-start">
                                 <div class="grid-content bg-purple hotel-name width-25"  >{{activeName=='hotelList'?item.hotelName:item.companyName}}</div>
                               </el-tooltip>
                               <!-- 第二列 -->
                               <div class="width-25" v-if="activeName=='hotelList'"><div class="grid-content bg-purple cyan content " >{{item.memberCount || 0}}</div></div>
                               <div class="width-25" v-if="activeName=='groupList'"><div class="grid-content bg-purple cyan content " >{{item.userCount || 0}}</div></div>
                               <div class="width-25" v-if="activeName=='leseeList'"><div class="grid-content bg-purple cyan content " >{{item.companyTotal || 0}}</div></div>
                               <!-- 第三列 -->
                               <div class="width-25" ><div class="grid-content bg-purple cyan content " >{{ (activeName=='groupList'?item.hotelCount:item.monthSale) || 0}}</div></div>
                               <!-- 第四列 -->
                               <div class="width-25"  v-if="activeName=='hotelList'"><div class="grid-content bg-purple cyan " ><p class='daily-sales' style="margin-right: 10px">{{item.todaySumMoney || 0}}</p></div></div>
                               <div class="width-25"  v-if="activeName=='groupList'"><div class="grid-content bg-purple cyan " ><p class='daily-sales' style="margin-right: 10px">{{item.monthSale || 0}}</p></div></div>
                               <div class="width-25"  v-if="activeName=='leseeList'"><div class="grid-content bg-purple cyan " ><p class='daily-sales' style="margin-right: 10px">{{item.hotelCount || 0}}</p></div></div>
                             </div>
                             <el-progress style="width: 100%" :text-inside="true" stroke-linecap="round"   :percentage="parseInt(item.percentage) || 0" :color="customColor"></el-progress>
                             <div  style="padding-left: 0;width: 50%"><div class="grid-content bg-purple cyan" style="font-size: 12px;">总销售额：{{item.totalMoney || 0}}</div></div>
                           </div>
                         </div>

                       </el-row>

                     </el-row>
                  </el-row>
               </el-col>
               <el-col :span="12" class="center-map">
                  <div class="box">
                     <div class="map">
                        <el-row class="head-data" type="flex" justify="center" style="align-items: center">
                           <div><p>今日新增用户</p><p>{{ mapData.user }}</p></div>
                           <div><p>今日新增会员</p><p>{{ mapData.member }}</p></div>
                           <div><p>今日新增酒店</p><p>{{ mapData.hotel }}</p></div>
                           <div><p>今日销售额</p><p>{{ mapData.todaySumMoney }}</p></div>
                           <div><p>本月销售额</p><p>{{ mapData.monthSale }}</p></div>
                           <div><p>今年销售额</p><p>{{ mapData.annualSale }}</p></div>
                        </el-row>
                        <div id="map-chart" style="width: 100%; height: 100%"></div>
                     </div>
                  </div>
                  <el-row class="box">
                     <el-row class="line">
                        <el-row class="tabs" type="flex">
                           <div :class="{ active: type === 'sales' }" @click="changeTab('sales')">销售额</div>
                           <div :class="{ active: type === 'user' }" @click="changeTab('user')">用户数</div>
                           <div :class="{ active: type === 'hotel' }" @click="changeTab('hotel')">酒店数</div>
                        </el-row>
                        <div id="sales-chart" style="width: 100%; height: 100%"></div>
                     </el-row>
                  </el-row>
               </el-col>
               <el-col :span="6" class="right-side side">
                  <el-row class="box">
                     <el-row class="data" id="regionTable">
                       <el-row class="sub-title">地区数据</el-row>
                        <el-row style="margin: 0 3px">
                          <el-table
                              :data="tableData"
                              :height="tableHeight"
                              :highlight-current-row="false"
                              :cell-style="{background: 'transparent'}"
                              style="width: 100%; background: transparent; border: none">
                            <el-table-column
                                prop="name"
                                label="省份">
                              <template slot-scope="scope">
                                <span style="color: #fff">{{scope.row.name}}</span>
                              </template>
                            </el-table-column>
                            <el-table-column
                                prop="total"
                                label="酒店数"
                                align="center">
                              <template slot-scope="scope">
                                <span class="cyan">{{scope.row.total}}</span>
                              </template>
                            </el-table-column>
                            <el-table-column
                                prop="increase"
                                align="right"
                                label="本月增长">
                              <template slot-scope="scope">
                                <span class="cyan">{{scope.row.increase}}</span>
                              </template>
                            </el-table-column>
                          </el-table>
                        </el-row>
                     </el-row>
                  </el-row>
                  <el-row class="box">
                     <el-row class="data"  id="orderTable">
                        <el-row class="sub-title">订单数据</el-row>
                       <el-row style="margin: 0 3px">
                         <el-table
                             :data="orderTableData"
                             :height="orderTableHeight"
                             :cell-style="{background:'transparent'}"
                             :stripe="true"
                             style="width: 100%;background: transparent;border: none">
                           <el-table-column
                               prop="name"
                               label="酒店名称"
                               show-overflow-tooltip
                               width="150">
                             <template slot-scope="scope">
                               <span style="color: #fff"> {{scope.row.hotelName}}</span>
                             </template>
                           </el-table-column>
                           <el-table-column
                               prop="total"
                               label="订单类型"
                               align="center"
                               width="80"
                               show-overflow-tooltip>
                             <template slot-scope="scope" v-if="scope.row.hasOwnProperty('createTime')">
                               <span class="cyan">{{scope.row.orderType | filterOrderType(that)}}</span>
                             </template>
                           </el-table-column>
                           <el-table-column
                               prop="increase"
                               align="center"
                               width="80"
                               label="售出价格">
                             <template slot-scope="scope">
                               <span class="cyan">{{scope.row.actualAmount}}</span>
                             </template>
                           </el-table-column>
                           <el-table-column
                               prop="increase"
                               align="right"
                               show-overflow-tooltip
                               label="售出时间">
                             <template slot-scope="scope" v-if="scope.row.hasOwnProperty('createTime')">
                               <p class="cyan" style="color: #29C8F0">{{scope.row.createTime}}</p>
                               <p class="cyan" style="color: #29C8F0; cursor: pointer" @click="goDetailPage(scope.row.id, '4', '1-0')">查看详情 ></p>
                             </template>
                           </el-table-column>
                         </el-table>
                       </el-row>
                     </el-row>
                  </el-row>
                  <el-row class="shop-tour" v-show="shopListShow">
                     <el-row class="sub-title" type="flex" justify="space-between">
                        <p>巡店系统</p>
                        <p>取消</p>
                     </el-row>
                     <el-row class="hotel-box" id="hotelBox">
                        <el-row class="search">
                           <el-input v-model="hotelName"  placeholder="点我输入" @input="searchHotel($event)"><i class="i"></i><img class="m-top-10 m-right-5" slot="suffix" src="@/assets/search.png" width="20"></el-input>
                        </el-row>
                       <el-row id="hotelListData" :style="{height:hotelListDataHigth}">

                         <el-row class="hotelList" v-for="item in hotelLIst" :key="item.id">
                              <div class="groupName">{{item.name}}</div>
                              <p class="hotelName" v-for="i in item.children" :key="i.id"><span @click="choiceHotel(i)">{{i.hotelName}}</span></p>
                         </el-row>
                       </el-row>
                     </el-row>
                  </el-row>
               </el-col>
            </el-row>
         </el-row>
      </el-drawer>
   </el-row>
</template>
<script>
import { urlObj } from "@/api/interface";
import { label } from '@/api/interface/data'
import {getDict, dateFactory, CustomArray} from '@/common/js/common'
import {mapState} from "vuex"; // 公共js
const delay = (function() {
		let timer = 0;
		return function(callback, ms) {
			clearTimeout(timer);
			timer = setTimeout(callback, ms);
		};
	})();

export default {
   data() {
      return {
        that:this,
         visibleDrawer: false,
        tableData:[],
        orderTableData:[],
        sellDataList:[],
        pickerTime:[],
        locaHotelLIst:[],
        hotelName:'',
        hotelLIst:[],
        tableHeight:'100', //地区数据表格高度
        orderTableHeight:'100', //地区数据表格高度
        sellTableHeight:'100px', //地区数据表格高度
        hotelListDataHigth:'100px',
        activeName:'hotelList',
        shopListShow:false,
        tabList:[
          {name:'酒店数据',value:'hotelList'},
          {name:'集团数据',value:'groupList'},
          {name:'租户数据',value:'leseeList'}
        ],
         salesData: {
            todaySumMoney:0,
            // saleCount: 0,
            monthSale: 0,
            annualSale: 0,
            hotel: 0,
            member: 0,
            user: 0
         },
         mapData: {
            todaySumMoney:0,
            // saleCount: 0,
            monthSale: 0,
            annualSale: 0,
            hotel: 0,
            member: 0,
            user: 0
         },
         type: '',
         lineData: {
            user: {},     // 用户数
            hotel: {},    // 酒店数
            sales: {}     // 销售数
         },
         percentage: 100,
         customColor:'rgb(170 68 236)',
         flag: true,
         showSuspensionBtn: true
      }
   },
   inject: ['reload'],
   computed: {
      ...mapState(['dictData'])
   },
   mounted() {
      const visibleDrawer = sessionStorage.getItem('visibleDrawer')
      this.visibleDrawer = visibleDrawer !== 'false'
      window.addEventListener('resize', this.getHeight);
   },
   methods: {
      // 打开数据看板
      open() {
         if (this.flag) {
            this.flag = false
            getDict(['order-type'], true)
            this.getHotelLocation()
            this.getMapDataCont()
            this.getUserHotelSales()
            this.queryStatistic()
            this.getOrderList()
            this.getStatisticByProvince()
            this.queryCompanyHotel()
            this.getMapDataCont()
            this.getHeight()
         }
      },
      // 离开悬浮按钮--
      leave() {
         setTimeout(() => this.showSuspensionBtn = true, 5000)
      },
      // 去订单详情页
      goDetailPage(id, menuIndex, sideIndex) {
         this.flag = false
         sessionStorage.setItem('visibleDrawer', 'false')
         sessionStorage.setItem('menuIndex', menuIndex)
         sessionStorage.setItem('sideIndex', sideIndex)
         this.$router.push({ path: '/preferential_mall/order_manage/detail', query: { id }})
         this.reload()
      },
      // 显示数据看板
      showDrawer() {
         this.visibleDrawer = !this.visibleDrawer
         sessionStorage.setItem('visibleDrawer', this.visibleDrawer)
      },
      // 监听打开数据看板
      getHeight() {
         if (document.getElementById('regionTable')) {
            this.tableHeight = document.getElementById('regionTable').clientHeight -40
            this.orderTableHeight = document.getElementById('orderTable').clientHeight -40
            this.sellTableHeight = document.getElementById('sellTable').clientHeight -120 + 'px'
            this.hotelListDataHigth = this.tableHeight + this.orderTableHeight -50 + 'px'
         }
      },
      // 选择酒店
      choiceHotel(val){
         let hotelInfo = JSON.parse(sessionStorage.getItem('hotelInfo'))
         hotelInfo = val
         sessionStorage.setItem('hotelInfo', JSON.stringify(hotelInfo))
         this.$store.dispatch('setHotelInfo', hotelInfo)
         this.visibleDrawer = false
         this.shopListShow = false
         this.hotelName = ''
      },
      //查询集团/酒店
      async searchHotel(event){
         if (event){
            this.hotelLIst =[]
            this.locaHotelLIst.forEach((i,o)=>{
               if (i.name.indexOf(event) >= 0 ){
                  this.hotelLIst.push(i)
               }else{
                  i.children.forEach(item=>{
                    console.log('locaHotelLIst--->',item)
                     if (item.hotelName.indexOf(event) >= 0 || item.pinyinName.indexOf(event) >=0){
                        this.hotelLIst.push(i)
                     }
                  })
               }
            })
            let customArray =  new CustomArray(this.hotelLIst)
            this.hotelLIst = customArray.unique()
         }
         else if(event == ''){
            this.hotelLIst =  this.locaHotelLIst
         }
      },
      // 获取地图数据
      getHotelLocation() {
         const url = urlObj.hotelLocation
         this.$axios.post(url, {}).then(res => {
            if (!res.success) return
            const { infos: data, local: coords } = res.records
            for (const item of data) {
               coords[item.name].push(170)
               item.value = coords[item.name]
            }
            const option = {
               /*tooltip:{ trigger: 'item' },*/
               geo: {
                  map: 'china',
                  zoom: 1.1,   // 放大
                  label: {
                     emphasis: {
                        show: false
                     }
                  },
                  roam: true,
                  itemStyle: {
                     normal: {
                        areaColor: 'rgba(2,37,101,.5)',
                        borderColor: 'rgba(112,187,252,.5)'
                     },
                     emphasis: {
                        areaColor: 'rgba(2,37,101,.8)'
                     }
                  }
               },
               grid:{
                  left: '0%',
                  right: '0%',
                  top: '0%',
                  bottom: '0%',
                  borderWidth: 1
               },
               series: [
                  {
                     name: '消费金额',
                     type: 'effectScatter',
                     coordinateSystem: 'geo',
                     rippleEffect: { brushType: 'stroke' },
                     data,
                     symbolSize: (val) => val[2] / 15,
                     label: {
                        normal: {
                           formatter: '{b}',
                           position: 'right',
                           show: false
                        },
                        emphasis: { show: true }
                     },
                     itemStyle: { normal: { color: '#ffeb7b' } }
                  }
               ]
            }
            this.$echarts.init(document.getElementById('map-chart')).setOption(option)
         })
      },
      // 获取地图数据统计
      getMapDataCont() {
         const salesUrl = urlObj.mapSalesCount;
         const salesParam = {
            beginTime: dateFactory.getMonthDate('start', false),
            endTime: dateFactory.getMonthDate('end', false),
            isPay: true,
            date: dateFactory.dateFormat(false)
         }
         const userUrl = urlObj.mapMemberCount
         const request = new Map([
            ['sales', this.$axios.post(salesUrl, salesParam)],
            ['user', this.$axios.post(userUrl, {})]
         ])
         this.$axios.all(request).then(res => {
            let salesData, userData
            if (res.get('sales').success) { salesData = res.get('sales').records }
            if (res.get('user').success) { userData = res.get('user').records }
            const data = Object.assign({}, salesData, userData)
            for (const k in this.mapData) k in data && (this.mapData[k] = data[k])
            this.url = label.getHotelData
            this.getHotelData()
         })
      },
      // 获取用户数/酒店数/销售额
      getUserHotelSales () {
         const request = new Map([
            ['user', this.$axios.post(urlObj.memberCount, {})],
            ['hotel', this.$axios.post(urlObj.hotelCont, {})],
            ['sales', this.$axios.post(urlObj.salesYear, {})]
         ])
         this.$axios.all(request).then(res => {
            res.get('user').success && (this.lineData.user = res.get('user').records)
            res.get('hotel').success && (this.lineData.hotel = res.get('hotel').records)
            res.get('sales').success && (this.lineData.sales = res.get('sales').records)
            this.changeTab('sales')
         })
      },
      // 切换销售额tab
      changeTab(type) {
         const option = {
            xAxis: {
               type: 'category',
               boundaryGap: false,
               axisLine: { show: true, lineStyle: { color: '#064d91', width: 1, type: 'solid' } },
               axisLabel: { textStyle: { color: '#5CAFEF' } },
               data: []
            },
            yAxis: {
               type: 'value',
               axisLabel: { textStyle: { color: '#5CAFEF' } },
               axisLine: { show: false, lineStyle: { color: '#064d91', width: 1, type: 'solid' } },
               splitLine: { show: true, lineStyle: { color: ['#064d91'], width: 1, type: 'solid' } },
            },
            grid:{
               left: '6%',
               right: '3%',
               top: '14%',
               bottom: '24%',
               borderWidth: 1
            },
            series: [
               {
                  data: [], type: 'line',
                  areaStyle: { normal: { color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                           { offset: 0, color: '#00EDFF'}, { offset: 0.5, color: '#00EDFF'}, { offset: 1, color: 'rgba(22, 139, 255,0.1)' } ])}
                  },
                  itemStyle: { normal: { color: '#5CAFEF', lineStyle: { color: '#5CAFEF' } } },
               }
            ]
         }
         this.type = type
         const data = this.lineData[type]
         for (const val of data) {
            option.xAxis.data.push(val.date)
            option.series[0].data.push(val.memberCount || val.hotelCount || val.sales)
         }
         this.$echarts.init(document.getElementById('sales-chart')).setOption(option)
      },
      // 退出登录
      logOut() {
         const url = urlObj.logout
         this.$axios.post(url, {}).then(res => {
            if (res.success) {
               this.$router.push('/login');
               sessionStorage.setItem('router', '/login')
            }
         }).catch(error => {
            let response = error.response;
            if(response && response.data && response.data.message){
               let message = response.data.message;
               message = message.split(':');
               if(message.length > 1){
                  // 您没有携带有效Token，无法访问后台服务！
                  return;
               }
            }
            this.$router.push('/login');
            sessionStorage.setItem('router', '/login')
         })
      },
      //获取合作数据
      queryStatistic(){
         let url = urlObj.queryStatistic,
         param = {};
         this.$axios.post(url, param).then(res => {
            if(res.success){
               this.$nextTick(()=>{
                  var cooperationchart = this.$echarts.init(document.getElementById('cooperation'));
                  let chart;
                  chart = {
                     xAxis: {type: 'value',axisLabel:{color:'#5CAFEF',},axisLine:{lineStyle: {color: '#0b4b86',}},splitLine: {lineStyle: {color: '#0b4b86',}}},
                     grid:{
                        left: '12%',
                        right: '12%',
                        top: '8%',
                        bottom: '26%',
                        borderWidth: 1
                     },
                     yAxis: {type: 'category',data: ['酒店数', '集团数', '租户数'],axisLabel:{color:'#fff',},axisLine: {lineStyle: {color: '#0b4b86',height:'10',}},
                     },
                     series: [
                        {
                           data: [res.records.hotel, res.records.company, res.records.tenant],
                           type: 'bar',
                           showBackground: false,
                           barWidth : 28,
                           itemStyle: {
                              normal: {
                                 color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0,[{offset: 1, color: '#168BFF'}, {offset: 0, color: '#92DF78'},]),
                                 barBorderRadius:[0,100,100,0],
                              },
                           },
                           label: {show: true,position: 'right', color: '#5CAFEF',valueAnimation: true},
                        },
                     ]
                  }
                  cooperationchart.setOption(chart);
                  window.onresize=()=>{
                     cooperationchart.resize()
                  }
               })
            }
         })
      },
      //获取地区数据
      getStatisticByProvince(){
         let url = urlObj.getStatisticByProvince,
             param = {

             };
         this.$axios.post(url, param).then(res => {
            if(res.success){
               this.tableData = res.records
               this.tableData.push({})
               this.total = res.total
            }
         })
      },
      //获取订单列表
      getOrderList(){
         let url = label.getorderlist,
             param = {
                isPay:true,
                page:1,
                limit:30
             };
         this.$axios.post(url, param).then(res => {
            if(res.success){
               this.orderTableData = res.records
               this.orderTableData.forEach(item => {
                  item.createTime = item.createTime.substring(0, 16)
               })
               this.orderTableData.push({})
            }
         })
      },
      //获取酒店数据
      queryCompanyHotel(){
         let url = label.queryCompanyHotel,
             param = {
             };
         this.$axios.post(url, param).then(res => {
            if(res.success){
               this.hotelLIst = this.locaHotelLIst = res.records
        }
      })
        // const url2 = urlObj.getHotelPage + `?limit=999&page=1`
        // const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        // const params = { hotelId: userInfo.extra && userInfo.extra.hotelId || '', companyId: userInfo.storeId || '' }
        // this.$axios.post(url2, params, 'json').then(res => {
        //   let arr = res.records
        //   console.log('拼音',this.locaHotelLIst[0].children)
        //   console.log('拼音',arr)
        //   if (res.success) {
        //     // let att = []
        //    this.locaHotelLIst.forEach((i)=>{
        //      arr.forEach((item)=>{
        //        i.children.forEach((items)=>{
        //          if(items.hotelName == item.hotelName){
        //            console.log('进来了')
        //            this.locaHotelLIst.children.push({
        //              'pinyinName':item.pinyinName
        //            })
        //          }
        //        })
        //      })
        //      console.log('dnm',this.locaHotelLIst)
        //      // let array = [...att,...this.locaHotelLIst]
        //      // console.log('[展开]',array)
        //      // this.locaHotelLIst = array
        //    })
        //   }
        // })
    },
    //获取销售数据
    getHotelData(){
       let param = {
            isPay:true,
            page:1,
            limit:9999,
            date:dateFactory.getDistanceToday(0,false),
            beginTime: dateFactory.getMonthDate('start', false),
            endTime: dateFactory.getMonthDate('end', false),
          };
      this.$axios.post(this.url, param).then(res => {
        if(res.success){
          this.sellDataList = res.records
          this.sellDataList.forEach(item=>{
             if (!('totalMoney' in item)) item.totalMoney = 0
            item.percentage = (item.totalMoney/this.mapData.annualSale)*100
            if (item.percentage >100)item.percentage =100
          })
           this.sellDataList.sort((a, b) => b.totalMoney - a.totalMoney)
          this.getHeight();
        }
      })
    },
    //切换销售数据
    handleClick(val){
      if (val.index == 0){
        this.url = label.getHotelData
      }
      if (val.index == 1){
        this.url = label.getGroupCompanyData
      }
      if (val.index == 2){
        this.url = label.getTenantData
      }
      this.getHotelData()

    }
  },
   watch: {
			hotelName() {
				delay(() => {
					this.searchHotel();
				}, 300);
			},
		},
  filters: {
    filterOrderType(val,that){
      let dictData = sessionStorage.getItem('dictData');
       if(that.dictData['order-type']){
          that.dictData['order-type']['E_BOOKING'] = '客房'
       }
      if (val && that.dictData['order-type']) return that.dictData['order-type'][val] || val
    },
  }

}
</script>
<style lang="scss" scoped>
   .suspension{
      position: fixed; bottom: 40px; right: 40px; z-index: 2000;
      ::v-deep .el-drawer__body{ overflow-x: auto }
      .screen-main{
         background: url("../../assets/screen-bg.png") no-repeat left top / 100% 100%; height: 100%;
         min-width: 1920px; overflow: auto;
         .top-title{
            .left { margin-top: 13px;font-size: 18px; color: #29C8F0; }
            .center { font-size: 34px; text-align: center;  }
            .right {
               padding: 0 0 0 71px; color: #29C8F0;
               span { line-height: 100px;cursor: pointer }
               span:nth-child(1) { font-size: 20px }
               span:nth-child(2) { margin-left: 60px; font-size: 18px }
               span.active { color: #FEEB82 }
            }
            color: #CFF3FF; height: 10%; padding: 10px 10px 0;
            background: url("../../assets/screen-head-bg.png") no-repeat left 70% / 100%; line-height: 76px;
         }
         .btm-cont{
            height: 90%; padding: 0 10px 10px;
            .side{
               height: 100%;
               .sub-title{
                  background-image: linear-gradient(to right, #107dc2, transparent); color: #FFFFFF;
                  line-height: 32px; padding-left: 30px; border-top-left-radius: 4px; position: relative;
               }
               .sub-title:after{
                  content: ''; width: 6px; height: 6px; background: #FEEB82; position: absolute; left: 16px;
                  top: 50%; transform: translateY(-3px);
               }
               .box{
                  height: 30%;
                  .data{
                     height: 100%; border-radius: 4px; box-shadow:rgb(42, 126, 197) 0 0 8px inset;
                    .market-data{
                      margin: 0 10px;
                       .el-tabs {
                          ::v-deep .el-tabs__item { color: #29C8F0; }
                          ::v-deep .el-tabs__active-bar{ background-color: #feeb82 }
                          ::v-deep .is-active { color: #feeb82 }
                       }
                      .content{
                        text-align: center;
                      }
                      .width-25{
                        width: 25%;
                      }
                      .daily-sales{
                        text-align: right
                      }
                      .tab-title{
                        color: #fff;
                        margin: 0;
                        border-bottom: 1px solid #1064a5;
                        padding-bottom: 10px;
                        font-size: 12px;
                        .unit{
                          text-indent: 4.5em
                        }
                      }
                      .tab-box{
                        overflow-y: auto;
                      }
                       ::v-deep .tab-data{
                        color: #fff;
                        margin: 0;
                        border-bottom: 1px solid #1064a5;
                        padding: 10px 0;
                        font-size: 14px;
                         .tab-box{
                            .grid-content { text-align: center!important; }
                         }
                        .hotel-name{
                          width: 25%;
                          overflow: hidden;
                          text-overflow:ellipsis;
                          white-space: nowrap;
                        }

                      }
                    }
                  }
               }
               .box:nth-child(2) { height: 70%; padding-top: 15px }
            }
            .left-side{

            }
            .center-map{
               height: 100%; padding: 0 10px;
               .box {
                  height: 60%;
                  .map, .line{ height: 100%; box-shadow:rgb(42, 126, 197) 0 0 8px inset; border-radius: 4px }
                  .map {
                     position: relative;
                     .head-data{
                        text-align: center; position: absolute; left: 0; top: 0; width: 100%; z-index: 999;
                        height: 80px; background: url("../../assets/map-head-bg.png") no-repeat left bottom / 100%;
                        div {
                           margin: 0 10px;
                           p:nth-child(1) { color: #FFFFFF; font-size: 14px; margin: 0 0 6px 0 }
                           p:nth-child(2) { color: #feeb82 }
                        }
                     }
                  }
                  .line {
                     .tabs{
                        background-image: linear-gradient(to right, #107dc2, transparent); color: #82B9FF;
                        line-height: 32px; padding-left: 30px; border-top-left-radius: 4px;
                        div{ margin-right: 54px }
                        div.active { color: #FEEB82; position: relative }
                        div.active:after {
                           content: ''; position: absolute; width: 100%; height: 2px;
                           background-color: #FEEB82; left: 0; bottom: 0;
                        }
                     }
                  }
               }
               .box:nth-child(2) { height: 40%; padding-top: 15px }
            }
            .right-side{
               position: relative;
               .shop-tour {
                  position: absolute; width: 100%; height: 100%; top: 0; left: 0; background: url("../../assets/shop-tour-bg.png") no-repeat left top / 100%;
                  box-shadow:rgb(42, 126, 197) 0 0 8px inset; border-radius: 4px;
                  .sub-title {
                     padding-right: 15px;
                     p:nth-child(1):after {
                        content: ''; width: 6px; height: 6px; background: #FEEB82; position: absolute; left: 16px;
                        top: 50%; transform: translateY(-3px);
                     }
                     p:nth-child(2) { font-size: 14px; color: #29C8F0 }
                  }
                  .hotel-box {
                    #hotelListData{
                      overflow-y: scroll;
                    }
                     .search {
                        padding: 30px 15px;
                        ::v-deep .el-input .el-input__inner { background: transparent; border: none; box-shadow:rgb(42, 126, 197) 0 0 8px inset; }
                     }
                    .hotelList{
                      color: #fff;
                      margin: 0 20px;
                      font-size: 14px;
                      .groupName{
                        text-indent: 2em;
                        padding: 7px 0;
                      }
                      .groupName:hover,.hotelName:hover{
                        color: #29C8F0;
                      }
                      .groupName:after{
                        content: ''; width: 6px; height: 6px; background: #29C8F0; position: absolute; left: 16px;
                        top: 17px; transform: translateY(-3px);
                      }
                      .hotelName{
                        text-indent: 3em;
                        cursor: pointer;
                      }
                    }

                  }
               }
            }
         }
      }
   }
   .cyan{
     color: #5CAFEF; font-size: 12px;
   }
   //进度条渐变
   /deep/ .el-progress-bar__inner{
     background-image:linear-gradient(to right,#a642ee,#da6699);
   }
   /deep/.el-progress-bar__outer{
     background-color:#013878;
   }
   /deep/  .el-progress-bar__innerText {
     display: none;
   }
   ::v-deep .el-progress-bar .el-progress-bar__outer { height: 4px!important; }
   //自定义表格样式
   /deep/ .el-table th.is-leaf, {
     background: transparent;
     color: #fff;
   }
   /deep/.el-table__row:after{
     content: ''; width: 95%; height: 1px; background: #0e5991; position: absolute; left: 2.5%;
   }
   /deep/.el-table tr{
     background: transparent;
     }
   /deep/.gutter{
     background: transparent;
   }
   /deep/.el-tooltip__popper{
     width: 400px;
     z-index: 9999999;
   }
   /* 修改表格的滚动条*/
 /deep/  .el-table__body-wrapper::-webkit-scrollbar {
       width: 5px;
       height: 10px;
       background-color: #023674;
   }
   /* 表格滚动条的滑块*/
   /deep/  .el-table__body-wrapper::-webkit-scrollbar-thumb {
       background-color: #0c5e95;
       border-radius: 3px;
   }
  /**修改全局的滚动条*//**滚动条的宽度*/
   ::-webkit-scrollbar {
     width: 5px;/*竖向*/        height: 5px;/*横向*/
   }
   /*滚动条的滑块*/
   ::-webkit-scrollbar-thumb {
     background-color: #0860a9;
     border-radius: 3px;
     width: 10px;
   }
   ::v-deep .el-table tr.current-row td{
      color: #409eff;
      background-color: red!important;
   }

 /deep/.el-table,{
    display: flex;
    background: #0c5e95;
    flex-direction: column;
    border: 0;
    th,tr,td{
       border: 0;
    }
    &::before{
      height: 0;
    }
   &::after{
     height: 0;

   }
   .el-table__fixed:before{
     height: 0;
   }
   /deep/.el-table .cell .el-table th div{
     padding-right: 0;
   }
 }
 //tab栏样式
 /deep/.el-tabs__nav-wrap::after{
   background-color: #1064a5;
 }
 /deep/.el-tabs__item{
   padding: 0 70px;
   color: #1064a5
 }
 /deep/.el-tabs__item.is-active {
   color: #37aed8;
 }
 /deep/ .el-tabs__item:focus.is-active.is-focus:not(:active){
   box-shadow: none;
   border-top-left-radius: 20px;
   border-top-right-radius: 20px;
 }

</style>
